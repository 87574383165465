import React, {useContext} from 'react';
import {Emergency, Htag} from "../components/exportComponent";
import {FormattedMessage, useIntl} from "react-intl";
import {GlobalContext} from "../context/globalContext";
import {Alert} from "@mui/material";

const OpenTimePage = (props) => {
    const {days,language}=useContext(GlobalContext)
    const {formatMessage} = useIntl()
    function vacationAlert(id,d){
        let f = formatMessage({id})?.replace('{{vacationFrom}}',d?.vacationFrom ).replace('{{vacationTo}}',d?.vacationTo);
        return f
    }

    return (
        <div className={'bg-white'}>
            <div className={'container'}>
                <div className={'d-flex flex-column col-12 align-items-center color-text-gray p-1 p-md-3'}>
                    <Htag text={<FormattedMessage id={'readyToServe'}/>}/>

                    {
                        (days != null & days?.vacationProcess !=='showVacation') ? days.data.map((e, index) =>
                            <div className={'col-12 col-md-6  d-flex my-1 gap-1 align-items-center justify-content-center'} key={index}>
                                <span className={'fw-bold col-4'}>{e[language.shortcut]}</span>
                                <div className={'line col-2'}></div>
                                {
                                    !e.closed ?
                                        <div className={'col-6 g-1 d-flex flex-wrap flex-sm-nowrap'}>
                                    <span
                                        className={`fw-bold w-100 ${e?.from == '00:00' && 'text-danger'} secondary-font`}>{e?.from}</span>


                                            <span
                                                className={`fw-bold w-100 ${e?.to == '00:00' && 'text-danger'} secondary-font`}>{e?.to}</span>
                                        </div>
                                        // <span className={`fw-bold w-100 ${e?.time == 'closed' && 'text-danger'} secondary-font`}>{e?.from} {e?.to}</span>
                                        :
                                        <span className={`fw-bold text-danger col-6 secondary-font`}>Closed</span>

                                }
                            </div>
                        )
                            :days?.vacationProcess == 'showVacation' &&
                            <span>
                           {vacationAlert('inVacation',days?.vacation)}
                       </span>
                    }
                    {
                        days?.vacationProcess == 'showAlert' &&
                        <Alert severity="warning">
                            {vacationAlert('vacationAlert',days?.vacation)}
                        </Alert>

                    }

                </div>
                <div className="line-v"></div>
                <div className={'col-12 my-3'}>
                    <Emergency/>
                </div>

            </div>
        </div>
    )
};

export default OpenTimePage;
