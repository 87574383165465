import React from 'react';
import {Helmet} from "react-helmet";
import {FormattedMessage} from "react-intl";

const Error = (props) => {
    return (
        <div className={'bg-white'}>
            <Helmet>
                <title>Luxembourg | Error</title>
            </Helmet>
            <div className={'container py-5 justify-content-center text-center d-flex flex-column align-items-center'}>
                <p className={'text-danger text-capitalize'}><FormattedMessage id={'pageNotFound'}/></p>
                <h1 className={'error-text'}>404</h1>
                <a href="/"><FormattedMessage id={'backHomePage'}/></a>
            </div>

        </div>
    )
};

export default Error;
