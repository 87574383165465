import React, {useEffect} from 'react';
import {Hero, OpenTime, TeamCard} from "../components/exportComponent";
import ContactPage from "./contactPage";
import ServicesPage from "./servicesPage";
import TeamPage from "./teamPage";
import Aboutus from "./aboutus";
import {Helmet} from "react-helmet";
import {useIntl} from "react-intl";

const HomePage = (props) => {
    const {formatMessage,locale} = useIntl()

    return (
        <>
            <Helmet htmlAttributes={{lang: locale == 'fr' ? 'fr-lu' : locale}}>
                <title>DENTAPAUL | {formatMessage({id: 'home'})}</title>
            </Helmet>
            <Hero/>
            <OpenTime/>
            <Aboutus/>
            <ServicesPage/>
            <ContactPage/>
            {/*<TeamPage/>*/}

        </>
    )
};

export default HomePage;
