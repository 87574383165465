import React from 'react';
import {FormattedMessage} from "react-intl";
import {LazyLoading} from "../exportComponent";

const Hero = (props) => {
    return (
        <div className={'position-relative w-100 overflow-hidden shadow d-flex'} style={{height:'600px'}}>
            {/*<div className="bg-dark bg-opacity-25 position-absolute w-100 h-100"></div>*/}
            <div className={'hero-background w-100 h-100 position-absolute '}></div>
            <LazyLoading  className={'w-100 position-absolute h-100 object-fit-cover'} src={'/images/slide3.jpg'} style={{zIndex:-1,objectPosition:"4% 2px",transform:'scaleX(-1)'}}/>
            {/*<img className={'w-100 position-absolute h-100 object-fit-cover'} src={'/images/slide3.jpg'} style={{zIndex:-1,objectPosition:"4% 2px",transform:'scaleX(-1)'}}/>*/}

            <div className={'w-100 position-absolute container mt-5 flex-wrap p-5 d-flex justify-content-start'} style={{left:'0',top:'20%'}}>
            <div className={'col-md-7 text-md-start mt-md-0 col-12'} >
                <h3 className={'color-text-secondary text-shadow display-6'}><FormattedMessage id={'heroSubTitle'}/></h3>
                <h1 className={'d-block display-2 fw-bold text-shadow color-text-primary'}><FormattedMessage id={'heroClinicName'}/></h1>
                <h6 className={'color-text-primary  fw-bold'}><FormattedMessage id={'HeroDetails'}/></h6>
            </div>
                {/*<OpenTime/>*/}
            </div>
        </div>
    )
};

export default Hero;


/*
<div className={'container position-absolute py-3 z-3 col-12 col-sm-9 col-md-6 text-center'} style={{marginTop:'10rem'}}>
    {/!*<h4>DON’T LOOK FURTHER, THIS IS YOUR DENTIST</h4>*!/}
    <h3 className={'text-white'}><FormattedMessage id={'welcome'}/></h3>
    <h1 className={'fw-bold my-3 text-white'}><FormattedMessage id={'drSamer'}/></h1>
    <h3 className={'text-white'}>Welcome to the dental practice of Dr. Samer Al Soufi</h3>
</div>
<div className="overflow btn-background position-absolute w-100 h-100"></div>
<img src="/images/42714045_1965102593584826_3115588968943452160_n.jpg" alt="" className={'img-fluid object-fit-cover w-100 h-100'}/>
*/
