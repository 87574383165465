import React from 'react';
import './style.scss'
import {NavLink as Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {CiMenuFries} from 'react-icons/ci'

export function closeNavbar(){
    // close navbar after click on navItem
    let n=document.getElementById('navbarNavDropdown')
    n.classList.remove('show')

    window.scroll(0,0)
}

const Navbar = (props) => {
    return(
        <nav className="navbar shadow  navbar-expand-lg p-2 sticky-top">
            <div className="container-fluid">
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <img src="/images/Asset 3.png" alt="" style={{maxWidth:'170px'}}/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <CiMenuFries/>
                    {/*<span className="navbar-toggler-icon"></span>*/}
                </button>
                <div className="collapse px-3 navbar-collapse mt-4 mt-md-0" id="navbarNavDropdown">
                    <ul className="navbar-nav text-capitalize d-flex justify-content-around m-auto gap-3 col-12 col-xl-12 col-xxl-8 align-items-center">
                        <li className="nav-item" onClick={closeNavbar}>
                            <Link to={'/'}>
                            <FormattedMessage id={'home'}/>
                            </Link>
                            {/*<a className="nav-link active" aria-current="page" href="#">Home</a>*/}
                        </li>
                        <div className="line-v d-lg-none"></div>
                        <li className="nav-item" onClick={closeNavbar}>
                            <Link to={'/contact'} >
                                <FormattedMessage id={'contact'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Features</a>*/}
                        </li>
                        <div className="line-v d-lg-none"></div>
                        <li className="nav-item" onClick={closeNavbar}>
                            <Link to={'/openingHours'} >
                                <FormattedMessage id={'openingHours'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Pricing</a>*/}
                        </li>
                        <div className="line-v d-lg-none"></div>
                        <li className="nav-item" onClick={closeNavbar}>
                            <Link to={'/services'}>
                                <FormattedMessage id={'services'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Pricing</a>*/}
                        </li>
                        <div className="line-v d-lg-none"></div>
                        <li className="nav-item" onClick={closeNavbar}>
                            <Link to={'/clinic'} >
                                <FormattedMessage id={'clinic'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Pricing</a>*/}
                        </li>
                        <div className="line-v d-lg-none"></div>
                        {/*<li className="nav-item dropdown">*/}
                        {/*    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"*/}
                        {/*       aria-expanded="false">*/}
                        {/*        Dropdown link*/}
                        {/*    </a>*/}
                        {/*    <ul className="dropdown-menu">*/}
                        {/*        <li><a className="dropdown-item" href="#">Action</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Another action</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        </nav>    )
};

export default Navbar;
