import en from "./en";
import fr from "./fr";
import de from "./de";



export default {
    ...en,
    ...de,
    ...fr
}
