import React from 'react';
import {ImLocation} from 'react-icons/im'
import {TfiEmail} from 'react-icons/tfi'
import {BsFillTelephoneFill} from 'react-icons/bs'
import line from "../assets/images/Asset 2@0.75x.png";
import {Htag, Form} from "../components/exportComponent";
import {FormattedMessage} from "react-intl";

const ContactPage = (props) => {
    const data = [
        {
            name: 'address',
            icon: <ImLocation/>,
            details: '4 rue du commerce \n' +
                'Dudelange 3450\n' +
                'Deuxième étage'
        },
        {
            name: 'email',
            icon: <TfiEmail/>,
            details: 'info@dentapaul.lu'
        }, {
            name: 'mobile',
            icon: <BsFillTelephoneFill/>,
            details: ['+352 2751-7132', '+352 621-357-247']
        },
    ]
    return (
        <div className={'page bg-body-tertiary'}>
            <Htag text={<FormattedMessage id={'contactusTitle'}/>}/>
            <h6 className={'color-text-gray text-center mb-5'}><FormattedMessage id={'contactusSubTitle'}/></h6>
            <div className={'py-3 lang'}>
                <div className={'d-flex flex-wrap justify-content-between container-xxl'}>
                <div className={'col-12'}>
                    <div className={'col-12 p-2'}>
                        <h1 className={'text-white  text-capitalize'}>
                            <FormattedMessage id={'directions'}/>
                        </h1>
                    </div>
                    <div className={'mt-3'}>
                        <iframe
                            title={'dentapaul clinic location'}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2592.278889736016!2d6.082966276853293!3d49.479238656815184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479537975619f4c9%3A0xd6af19aaf599ae47!2sDr.%20RUSTOM%20Paul%20_%20Dentapaul!5e0!3m2!1sen!2sde!4v1688080096038!5m2!1sen!2sde"
                            style={{
                                width: "100%", height: "100%", minHeight: 500
                            }}  loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
                <div className={'col-12 mt-3 flex-wrap d-flex  p-2 align-items-start text-white'}>
                    <div className={'col-12'}>
                        <h1><FormattedMessage id={'bookAppointment'}/></h1>
                        <h6 className={'m-auto text-light-blue'}>
                            <FormattedMessage id={'fillForm'}/>
                        </h6>
                    </div>
                    <div className={'col-12 mt-5 col-md-6 d-flex flex-column gap-5'}>
                        {
                            data.map((e, index) =>
                                <div key={index} className={'col-6 col-md-12 gap-3 d-flex align-items-center'}>
                                    <span className={'display-6'}>{e.icon}</span>
                                    <div className={'col-9'}>
                                        <h5 className={'fw-bold text-lowercase text-uppercase'}>{<FormattedMessage
                                            id={e.name}/>}</h5>
                                        <h6 className={'secondary-font'}>{

                                            typeof e.details =='object' ? e.details.map(n=><span key={n} className={'d-block'}>{n}</span>):e.details

                                        }</h6>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/*<div className="line d-none d-md-block"></div>*/}
                    <div className={'col-12 col-md-6 mt-5'}>
                        <Form/>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
};

export default ContactPage;
