import {LAN} from '../i18n/locales'
export default {
    [LAN.Deutsch]:{
        home:'Startseite',
        contact:'Kontakt',
        openingHours:'sprechstunde',
        openinghours:'sprechstunde',
        services:'Dienstleistungen',
        drSamer:'Dr. Samer Alsoufi',
        address:'Adresse',
        mobile:'Mobile',
        email:'E-mail',
        openTime:'sprechstunde',
        customerServices:'Was wir unseren Kunden bieten',
        team:'Unser professionelles Team',
        MakeAppointment:'Machen Sie den ersten Schritt zu einem gesünderen Lächeln, indem Sie einen Termin mit Dentil vereinbaren. Unser freundliches Team steht Ihnen gerne zur Verfügung und beantwortet alle Ihre Fragen. Kontaktieren Sie uns per Telefon oder E-Mail oder nutzen Sie unser praktisches Online-Buchungssystem. Wir freuen uns darauf, Ihnen zu einer optimalen Mundgesundheit und einem selbstbewussten Lächeln zu verhelfen.',
        whyChooseusDetails:'Expert Dentists: Our skilled dentists have years of experience and stay updated with the latest advancements in dentistry.\n' +
            'Advanced Technology: We utilize cutting-edge dental technology to deliver precise diagnoses and comfortable treatments.\n' +
            'Personalized Care: We understand that each patient is unique, and we provide tailored treatment plans to address your specific needs.\n' +
            'Comfortable Environment: Our clinic is designed to create a relaxing and welcoming atmosphere, ensuring a stress-free dental experience.\n' +
            'Patient Education: We believe in empowering our patients with ',
        clinic:'Klinik',
        heroSubTitle:'Entdecken Sie Ihr perfektes Lächeln unter',
        heroClinicName:'DENTAPAUL',
        HeroDetails:'Zahnarztpraxis mit Dr. Rustom Paul, \'mehr als 15 Jahre Erfahrung\'',
        servicesTitle:'Umfassende Dentallösungen',
        servicesSubtitle:'Verwandeln Sie Ihr Lächeln mit fortschrittlicher Zahnheilkunde',
        abutusTitle:'Über uns',
        aboutusSubtitle:'Ihre vertrauenswürdigen Zahnpflegepartner',
        aboutusDesc:'Erlauben Sie mir, Dr. RUSTOM Paul vorzustellen, einen hochqualifizierten und engagierten Fachmann auf dem Gebiet der Zahnmedizin und Chirurgie mit einer Spezialisierung auf Ästhetik. Mit einer beeindruckenden 15 Jahren Erfahrung ist Dr. RUSTOM Paul darauf bedacht, seinen Patienten außergewöhnliche Qualität in der Betreuung zu bieten. Sein vorrangiges Ziel ist es sicherzustellen, dass jeder einzelne die bestmöglichen Ergebnisse erhält, indem er aufmerksam auf ihre Anliegen und Bedürfnisse eingeht. Angetrieben von echten Mitgefühl für das Wohl seiner Patienten ist er stets präsent und aufmerksam, insbesondere bei der Schmerzlinderung und Bewältigung von Notfällen. Dr. RUSTOM Paul ist ein vertrauenswürdiger und mitfühlender Zahnarzt, der sich der Verbesserung der Mundgesundheit und der allgemeinen Zufriedenheit und der allgemeinen zufriedenheit seiner patienten widmet',
        contactusTitle:'So erreichen Sie uns',
        contactusSubTitle:'Die von umweltfreundlichen Systemen sehr begeistert sind.',
        teamTitle:'Lernen Sie unser leidenschaftliches Dentalteam kennen',
        teamSubtitle:'Engagiert für Ihre Mundgesundheit und Ihr strahlendes Lächeln',
        followus:'Folgen Sie uns',
        links:'Links',
        contactus:'kontaktieren sie uns',
        bookAppointment:'Vereinbaren sie einen Termin',
        makeAppointment:'Termin Vereinbaren',
        fillForm:' Bitte füllen Sie dieses Formular aus, und wir werden uns bald mit den Einzelheiten Ihres Termins bei Ihnen melden.',
        readyToServe:'Wir sind bereit, Sie zu bedienen in dieser Zeit.',
        directions:'parxilage / anfahrt',
        backHomePage:'Zurück zur Startseite',
        pageNotFound:'Seite nicht gefunden',
        visualJourney:'Eine visuelle Reise',
        clinicSubTitle:'Sie erhalten Beratung und Behandlung in einer entspannten Umgebung.',
        message:'Wir haben Ihre Nachricht erhalten',
        fullName:'Vollständiger Name',
        formMessage:'Nachricht',
        send:'Senden',
        agreePolicy:'Hiermit stimme ich der Verwendung und Speicherung meiner Daten für Kontaktzwecke und der Datenschutzbelehrung zu',
        inCaseEmergency:'im Notfall',
        clickToCall:'Klicken Sie hier um uns anzurufen',
        emergencyDetails:'Im Notfall rufen Sie bitte umgehend unsere Zahnarztpraxis an $link$. Wir sind hier, um Ihnen zu helfen und Ihnen die notwendige zahnärztliche Versorgung zu bieten. Ihre Mundgesundheit hat für uns oberste Priorität, und unser Team steht Ihnen zur Seite. Zögern Sie nicht, uns zu kontaktieren. Wir sind verfügbar, um auf Ihre Bedürfnisse einzugehen und Ihnen schnelle und professionelle Unterstützung anzubieten. Verlassen Sie sich auf uns, um Sie in zahnärztlichen Notfallsituationen zu betreuen',
        inVacation: 'Wir möchten Sie darüber informieren, dass unsere Zahnarztpraxis derzeit geschlossen ist. Wir entschuldigen uns für etwaige Unannehmlichkeiten und bedanken uns für Ihr Verständnis. Wir werden am {{vacationTo}} wieder öffnen.',
        vacationAlert:'Wir bedauern, Ihnen mitteilen zu müssen, dass unsere Zahnklinik von {{vacationFrom}} bis {{vacationTo}} vorübergehend geschlossen ist'
    }
}
