import {LAN} from '../i18n/locales'
export default {
    [LAN.ENGLISH]:{
        home:'Home',
        contact:'contact',
        openingHours:'business hours',
        openinghours:'business hours',
        services:'services',
        drSamer:'Dr. Samer Alsoufi',
        address:'address',
        mobile:'Mobile',
        email:'e-mail',
        openTime:'Working time',
        customerServices:'What We Offer To Our Customers',
        team:'Our Professional Team',
        MakeAppointment:'Take the first step towards a healthier smile by scheduling an appointment with Dentil. Our friendly team is ready to assist you and answer any questions you may have. Contact us via phone or email, or use our convenient online booking system. We look forward to helping you achieve optimal oral health and a confident smile.',
        whyChooseusDetails:'Expert Dentists: Our skilled dentists have years of experience and stay updated with the latest advancements in dentistry.\n' +
            'Advanced Technology: We utilize cutting-edge dental technology to deliver precise diagnoses and comfortable treatments.\n' +
            'Personalized Care: We understand that each patient is unique, and we provide tailored treatment plans to address your specific needs.\n' +
            'Comfortable Environment: Our clinic is designed to create a relaxing and welcoming atmosphere, ensuring a stress-free dental experience.\n' +
            'Patient Education: We believe in empowering our patients with ',
        clinic:'clinic',
        heroSubTitle:'Discover Your Perfect Smile at',
        heroClinicName:'DENTAPAUL',
        HeroDetails:'Dental Clinic with Dr. Rustom Paul "15+ Years of experience"',
        // HeroDetails:'Experience Exceptional Dental Care Tailored to Your Needs and Unleash the Power of Your Confident, Healthy Smile',
        servicesTitle:'Comprehensive Dental Solutions',
        servicesSubtitle:'Transforming Smiles with Advanced Dentistry',
        abutusTitle:'About Us',
        aboutusSubtitle:'Your Trusted Dental Care Partners',
        aboutusDesc:'Allow me to introduce Dr. RUSTOM Paul, a highly experienced and dedicated professional in the field of dental care and surgery, with a specialization in esthetics. With an impressive 15 years of expertise under his belt, Dr. RUSTOM Paul is committed to providing exceptional quality care to his patients. His primary objective is to ensure that each individual receives the best possible results, and he achieves this by actively listening to their concerns and needs. Driven by a genuine compassion for his patients\' well-being, he remains present and attentive, particularly in relieving pain and managing emergency cases. Dr. RUSTOM Paul is a trusted and compassionate dental practitioner, dedicated to enhancing the oral health and overall satisfaction of those he serves.',
        contactusTitle:'How You Can Reach Us',
        contactusSubTitle:'Who are in extremely love with eco friendly system.',
        teamTitle:'Meet Our Passionate Dental Team',
        teamSubtitle:'Dedicated to Your Oral Health and Radiant Smiles',
        followus:'Follow Us',
        links:'Links',
        contactus:'contact us',
        bookAppointment:'Book an appointment',
        makeAppointment:'Make Appointment',
        fillForm:'Kindly fill out this form, and we will get back to you soon with the details of your appointment.',
        readyToServe:'we are ready to serve you on this times',
        directions:'location of clinic / directions',
        backHomePage:'Back to home page',
        pageNotFound:'Page not found',
        visualJourney:'A Visual Journey ',
        clinicSubTitle:'You will receive, advice and treat in a relaxed place',
        message:'We have got your message',
        fullName:'Full Name',
        formMessage:'Message',
        send:'Send',
        clickToCall:'click here to call us',
        agreePolicy:'I hereby consent to the use and storage of my data for contact purposes and agree to the privacy policy.',
        inCaseEmergency:'in case of emergency',
        vacationAlert:'We regret to inform you that our dental clinic will be temporarily closed from {{vacationFrom}}    to    {{vacationTo}}',
        inVacation:'We want to inform you that our dental office is currently closed. We apologize for any inconvenience this may cause and appreciate your understanding.' +
           'We will reopen on {{vacationTo}}.\n',
        emergencyDetails:'In case of emergency, please call our dental clinic without delay $link$. We are here to help and provide you with the necessary dental care. Your oral health is our priority, and our team is ready to assist you. Do not hesitate to contact us. We are available to meet your needs and offer you prompt and professional assistance. Count on us to take care of you in dental emergency situations'







    }
}
