import {LAN} from '../i18n/locales'
export default {
    [LAN.France]:{
        home:'d\'accueil',
        contact:'contact',
        openingHours:'Horaires d\'ouvertures',
        openinghours:'Horaires d\'ouvertures',
        services:'prestations de service',
        drSamer:'د.سامر الصوفي',
        address:'adresse',
        mobile:'mobile',
        email:'E-mail',
        openTime:'Temps de travail',
        customerServices:'Ce que nous offrons à notre patient',
        team:'Notre équipe professionnelle',
        locationContact:'Comment nous joindre',
        clinic:'clinique',
        MakeAppointment:'Faites le premier pas vers un sourire plus sain en prenant rendez-vous avec Dentil. Notre équipe amicale est prête à vous aider et à répondre à toutes vos questions. Contactez-nous par téléphone ou par e-mail, ou utilisez notre système de réservation en ligne pratique. Nous sommes impatients de vous aider à atteindre une santé bucco-dentaire optimale et un sourire confiant.'
    ,        whyChooseusDetails:'Expert Dentists: Our skilled dentists have years of experience and stay updated with the latest advancements in dentistry.\n' +
            'Advanced Technology: We utilize cutting-edge dental technology to deliver precise diagnoses and comfortable treatments.\n' +
            'Personalized Care: We understand that each patient is unique, and we provide tailored treatment plans to address your specific needs.\n' +
            'Comfortable Environment: Our clinic is designed to create a relaxing and welcoming atmosphere, ensuring a stress-free dental experience.\n' +
            'Patient Education: We believe in empowering our patients with ',
        heroSubTitle:'Découvrez votre sourire parfait sur',
        heroClinicName:'DENTAPAUL',
        HeroDetails:'Clinique dentaire avec le Dr Rustom Paul, \'plus de 15 ans d\'expérience\'.',
        servicesTitle:'Solutions dentaires complètes',
        servicesSubtitle:'Transformer les sourires avec la dentisterie avancée',
        abutusTitle:'À propos de nous',
        aboutusSubtitle:'Vos partenaires de soins dentaires de confiance',
        aboutusDesc:'Permettez-moi de vous présenter le Dr RUSTOM Paul, un professionnel hautement expérimenté et dévoué dans le domaine des soins dentaires et de la chirurgie, avec une spécialisation en esthétique. Avec une impressionnante expertise de 15 ans à son actif, le Dr RUSTOM Paul s\'engage à fournir des soins d\'une qualité exceptionnelle à ses patients. Son objectif principal est de garantir que chaque individu obtienne les meilleurs résultats possibles, et il y parvient en écoutant activement leurs préoccupations et leurs besoins. Animé d\'une véritable compassion pour le bien-être de ses patients, il reste présent et attentif, notamment pour soulager la douleur et gérer les cas d\'urgence. Le Dr RUSTOM Paul est un praticien dentaire de confiance et compatissant, dédié à l\'amélioration de la santé bucco-dentaire et à la satisfaction globale de ceux qu\'il sert',
        contactusTitle:'Comment nous joindre',
        contactusSubTitle:'Qui sont extrêmement amoureux du système écologique.',
        teamTitle:'Rencontrez notre équipe dentaire passionnée',
        teamSubtitle:'Dédié à votre santé bucco-dentaire et à des sourires radieux',
        followus:'Suivez nous',
        links:'Liens',
        contactus:'Contactez nous',
        bookAppointment:'Prenez rendez vous',
        makeAppointment: 'Prendre un rendez-vous',
        fillForm:' Veuillez remplir ce formulaire, et nous vous contacterons prochainement avec les détails de votre rendez-vous.',
        readyToServe:'Nous sommes prêts à vous servir en ces temps.',
        directions:'Emplacement Du Cabinet / Itinéraire',
        backHomePage:'Retour à la page d\'accueil',
        pageNotFound:'Page introuvable',
        visualJourney:'Un voyage visuel',
        clinicSubTitle:'Vous recevrez des conseils et des soins dans un cadre relaxant',
        message:'Nous avons bien reçu votre message',
        fullName:'Nom Complet',
        formMessage:'Message',
        send:'Envoyer',
        clickToCall:'Cliquez ici pour nous appeler',
        agreePolicy:' Par la présente, j\'accepte l\'utilisation et le stockage de mes données à des fins de contact ainsi que la politique de confidentialité.',
        inCaseEmergency:'en cas d\'urgence',
        emergencyDetails:'En cas d\'urgence, veuillez appeler notre clinique dentaire sans délai. Nous sommes là pour vous aider et vous fournir les soins dentaires nécessaires. Votre santé bucco-dentaire est notre priorité, et notre équipe est prête à vous assister. Ne tardez pas à nous contacter. \n' +
            'Nous sommes disponibles pour répondre à vos besoins et vous offrir une assistance rapide et professionnelle $link$. Comptez sur nous pour prendre soin de vous dans les situations d\'urgence dentaire',
        vacationAlert: 'Nous avons le regret de vous informer que notre clinique dentaire sera temporairement fermée du {{vacationFrom}} au {{vacationTo}}',
        inVacation: 'Nous tenons à vous informer que notre cabinet dentaire est actuellement fermé. Nous nous excusons pour tout inconvénient que cela pourrait causer et apprécions votre compréhension. Nous rouvrirons le {{vacationTo}}'










    }

}
