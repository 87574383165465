import React from 'react';
import {AiFillCloseCircle} from 'react-icons/ai'
import {ImageSlider} from "../exportComponent";

function FullWidthImg ({imgs,setCloseImg}) {



    return(
        <div className={'vh-100 justify-content-center position-relative align-items-center vw-100 position-fixed d-flex bg-body-secondary'} style={{zIndex:1500}}>

           <span onClick={()=>setCloseImg(false)} className={'position-absolute pointer-event-cursor display-1 mt-2 top-0'}><AiFillCloseCircle/></span>
            {
                typeof imgs?.data =='object' ?
                    <div className={'col-12 h-75'}>
                    <ImageSlider slider={false} imagesData={imgs}/>
                    </div>:


                    <div>
                        <img src={imgs.data} className={'img-fluid object-fit-cover'} alt="" style={{maxHeight:'80%'}}/>
                    </div>


            }
        </div>
    )
};

export default FullWidthImg;
