import {createContext, useEffect, useState} from "react";
import axios from "axios";

export const GlobalContext = createContext(null)

export const GlobalContextProvider = (props) => {
    const [language, setLanguage] = useState({
        name: 'franc',
        shortcut: 'fr',
        direction: 'ltr'
    })
    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState(null)
    const [imgs, setImgsContext] = useState(null)
    const [imgState, setImgState] = useState(false)
    const [settings, setSettings] = useState(null)
    const setImgs = (d, index) => {
        setImgState(true)
        setImgsContext({data: d, index})

    }
    const _setLanguageToContext = (l) => {
        setLanguage(JSON.parse(l))
        localStorage.setItem('clientLanguage', l)
    }

    useEffect(() => {
        getSettings().then(res => setSettings(res.data.data)).catch(e => {
            setSettings({
                "closeWebsite": 0,
                "blockEmails": 0
            })
        })
    }, [])

    useEffect(() => {
        let x = localStorage.getItem('clientLanguage') || null
        if (x != null) {
            _setLanguageToContext(x)
        }
        getData().then(res => setDays(res.data)).catch(e => {
            return
        })

        // close img if it is open when press back button or change location some how
        function f() {
            setImgState(false)
        }

        window.addEventListener('popstate', f)
        return () => window.removeEventListener('popstate', f)
    }, [])

    function getData() {
        try {
            return axios.get('https://backend.dentapaul.lu/public/index.php/api/getDays')
        } catch (e) {
            return
        }
    }

    async function getSettings() {
        try {
            return await axios.get('https://backend.dentapaul.lu/public/index.php/api/getSettings')
        } catch (e) {
            return
        }
    }

    return (
        <GlobalContext.Provider
            value={{
                language,
                _setLanguageToContext,
                loading,
                setLoading,
                imgState,
                setImgState,
                setImgs,
                settings,
                imgs,
                days
            }}>
            {props.children}
        </GlobalContext.Provider>
    )
}
