import React, {useContext, useEffect, useState} from 'react';
import {Htag} from '../exportComponent'
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import data from '../../assets/tsconfig.json'
import axios from 'axios'
import {GlobalContext} from "../../context/globalContext";
import {Alert} from "@mui/material";


const OpenTime = (props) => {
    const {language,days}=useContext(GlobalContext)
    const {formatMessage} = useIntl()
    function vacationAlert(id,d){
        let f = formatMessage({id})?.replace('{{vacationFrom}}',d?.vacationFrom ).replace('{{vacationTo}}',d?.vacationTo);
        return f
    }

    return (
        <div className={'container-xl text-center openTime z-2 align-content-stretch d-flex flex-wrap'}>
            <div className={'btn-background col-md-6 d-flex flex-column col-12 p-1 p-md-3 text-white'}>
                <Htag text={<FormattedMessage id={'openingHours'}/>}/>
                {/*<h1 className={'fw-bold'}>opening Hours</h1>*/}
                {/*{*/}
                {/*    data?.times.map((e, index) =>*/}
                {/*        <div className={'col-12 text-nowrap d-flex gap-md-1 my-1 gap-1 justify-content-center'}*/}
                {/*             key={index}>*/}
                {/*            <span className={'fw-bold w-100'}>{e?.day}</span>*/}
                {/*            :*/}
                {/*            <span*/}
                {/*                className={`fw-bold w-100 ${e?.time == 'closed' && 'text-danger'} secondary-font`}>{e?.time}</span>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}
                {
                    // console.log(dynamicData)
                    (days != null & days?.vacationProcess !=='showVacation') ? days.data.map((e, index) =>
                        <div className={'col-12 text-nowrap d-flex gap-md-1 my-1 gap-1 justify-content-center'} key={index}>
                            <span className={'fw-bold w-100'}>{e[language?.shortcut]}</span>
                            :
                            {
                                !e?.closed ?
                                    <>
                                    <span
                                        className={`fw-bold w-100 ${e?.from == '00:00' && 'text-danger'} secondary-font`}>{e?.from}</span>
                                    -
                                    <span
                                        className={`fw-bold w-100 ${e?.to == '00:00' && 'text-danger'} secondary-font`}>{e?.to}</span>
                                    </>
                                    :
                                    <>
                                    <span
                                        className={`fw-bold w-100 text-danger secondary-font`}>closed</span>

                                        <span
                                            className={`fw-bold w-100 ${e?.to == '00:00' && 'text-danger'} secondary-font`}></span>
                                    </>

                            }
                        </div>
                    ):days?.vacationProcess == 'showVacation' &&
                       <span>
                           {vacationAlert('inVacation',days?.vacation)}
                       </span>
                }
                {
                    days?.vacationProcess == 'showAlert' &&
                    <Alert severity="warning">
                        {vacationAlert('vacationAlert',days?.vacation)}
                    </Alert>

                }
                <a href={'#form'} className={'btn-primary w-100 mt-auto p-3 btn bg-dark'}><FormattedMessage
                    id={'bookAppointment'}/></a>

            </div>
            {/*style={{backgroundColor:'#F57E57'}}*/}
            <div className={'col-md-6 col-12 d-flex flex-column p-4 bg-dark text-white'}>
                <Htag text={<FormattedMessage id={'makeAppointment'}/>}/>
                {/*<h1 className={'fw-bold'}>opening Hours</h1>*/}
                <div className={'justify-content-center align-items-center'}>
                    <FormattedMessage id={'MakeAppointment'}/>
                </div>
                <Link state={{name: 'contact'}} to={'/contact'}
                      className={'btn-primary w-100 p-3 mt-auto btn btn-background'}>
                    <FormattedMessage
                        id={'contactus'}/>
                </Link>
            </div>
        </div>
    )
};

export default OpenTime;
