import React, {useContext, useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {MyTextField, MyTextarea} from "../myTextField/myTextField";
import {useNavigate} from 'react-router-dom'
import {GlobalContext} from "../../context/globalContext";
import {FormattedMessage, useIntl} from "react-intl";

const Form = (props) => {
    const {formatMessage} = useIntl()
    const navi = useNavigate()
    const [info, setInfo] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const {setLoading, settings} = useContext(GlobalContext)
    const pushInfo = (val, text) => {
        setInfo({...info, [text]: val}
        )
    }
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();
        {
            !settings?.blockEmails &&
            setLoading(true)
            try {
                emailjs.sendForm('service_2snsb08', 'template_j07xiag', form.current, 'S9ZECkiWFsa_hAb_5')
                    .then((result) => {
                        if (result.text.toLowerCase() == 'ok') {
                            navi('/message', {state: {name: info.name}})
                        }
                    }, (error) => {
                        alert('some think went wrong')
                    }).finally(() => setLoading(false));

            } catch (e) {
                setLoading(false)
            }
        }

    };
    return (
        <div className={'formContainer'}>
            <form onSubmit={(e) => sendEmail(e)} id={'form'} className={'contactForm'} ref={form}>
                <MyTextField type="text" name={'form_name'} placeholder={formatMessage({id: 'fullName'})}
                             value={info?.name || ''}
                             required onChange={(e) => pushInfo(e.target.value, 'name')}/>
                <MyTextField type="Email" name={'form_email'} placeholder={formatMessage({id: 'email'})}
                             value={info?.email || ''}
                             required onChange={(e) => pushInfo(e.target.value, 'email')}/>
                <MyTextField type="number" name={'form_mobile'} placeholder={formatMessage({id: 'mobile'})}
                             defaultValue={info?.mobile || ''}
                             required
                             onChange={(e) => pushInfo(e.target.value, 'mobile')}

                />
                <MyTextarea name={'message'} className={'p-3'} placeholder={formatMessage({id: 'formMessage'})}
                            value={info?.message || ''}
                            required onChange={(e) => pushInfo(e.target.value, 'message')}/>
                {/*{*/}
                {/*    browser?.includes('chrome') || googleKey &&*/}
                {/*    <ReCAPTCHA sitekey={googleKey && googleKey || ''}*/}
                {/*               onExpired={()=>setIsTrusted(false)}*/}
                {/*               onChange={() =>*/}
                {/*                   setIsTrusted(true)*/}
                {/*               }/>*/}

                {/*}*/}

                {/*{message &&*/}
                {/*<h4 className={message && message.toLowerCase().includes('successfully') ? `text-success` : ''}>{message}</h4>*/}
                {/*}*/}
                <div className={'d-flex gap-1'}>
                    <div>
                        <input className="form-check-input" type="checkbox" checked={!disabled} id="flexCheckDefault"
                               onChange={() => setDisabled(!disabled)}
                        />
                    </div>
                    <span className={'d-block'}><FormattedMessage id={'agreePolicy'}/></span>
                </div>
                <button type={"submit"}
                        disabled={disabled}
                        className={'btn btn-primary btn-background text-capitalize'}
                    // disabled={!isTrusted}
                    // ref={reCapForm}
                >{formatMessage({id: 'send'})}
                </button>
            </form>
        </div>

    )
};

export default Form;
