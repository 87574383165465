import React, {useContext} from 'react';
import {Htag, ImageSlider} from "../components/exportComponent";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../context/globalContext";

const Clinic = (props) => {
    const {setImgs}=useContext(GlobalContext)
    const images =['/images/slide1.jpg',"/images/slide2.jpg","/images/slide3.jpg"]
    const subImages=['/images/slide-1.jpg',"/images/slide-2.jpg","/images/slide-3.jpg","/images/girl1.jpg","/images/girl2.jpg"]
    return(
        <div className={'w-100'}>
            <Htag text={<FormattedMessage id={'visualJourney'}/>}/>
            {/*<h3 className={'fw-bold text-center'}>What we Offer to our Customers</h3>*/}
            <h6 className={'color-text-gray text-center mb-5'}><FormattedMessage id={'clinicSubTitle'} defaultMessage={'translating Error'}/></h6>

            <div className={'w-100 position-relative overflow-hidden my-4'}  style={{height:"500px"}}>
                <ImageSlider imagesData={images} slider={true}/>
            </div>
            <h6 className={'color-text-gray text-center mb-5'}>Who are in extremely love with eco friendly system.</h6>

            <div className={'d-flex mb-3 flex-wrap row-gap-2 align-items-center justify-content-center container-xxl'}>
                {
                    subImages.map((e,index)=>
                        <div key={e}  className={'col-12 col-md-6 col-lg-6 overflow-hidden  position-relative d-flex justify-content-center'}>
                            <img src={'/images/Asset 3.png'} className={'position-absolute w-25 bottom-0'} style={{left:'3%',maxWidth:'60px'}}/>
                            <img src={e} className={'img-fluid object-fit-cover pointer-event-cursor'} style={{maxWidth:'95%'}} alt="dentapaul images" onClick={()=>setImgs(subImages,index)}/>
                        </div>

                    )
                }
            </div>
            {/*<img src={'/images/klinik.jpg'} alt="Slider Image" className={'object-fit-cover r img-fluid'} />*/}

        </div>
    )
};

export default Clinic;
