import React, {useContext, useEffect, useState} from 'react';
import {Link,useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../context/globalContext";

const BreadCrumb = (props) => {
    const [paths, setPaths] = useState([])
    const location=useLocation()


    useEffect(() => {
        const r = window.location.pathname
        const p = r.split('/')
        setPaths(p)


    }, [location])
    return (
        <div className="w-100">
            <div className="col-12 d-flex justify-content-center">
                {
                    paths.map((p,index) =>
                        p == '' ? null : <div key={index} className={'d-flex h6 gap-2 justify-content-start align-items-center'}>
                            <Link to={`/`} className={'text-capitalize text-light letter-spacing'} ><FormattedMessage id={'home'}/></Link>
                            /
                            <span to={`/${p}`} className={'text-capitalize text-light letter-spacing text-start'} >
                                {p.toLowerCase() =='message'?'':
                                <FormattedMessage id={p.toLowerCase()}/>
                                }

                            </span>
                        </div>
                    )
                }

            </div>
        </div>
    )
};

export default BreadCrumb;
