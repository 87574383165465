import React, {Fragment, useContext} from "react";
import {IntlProvider} from "react-intl";
import messages from '../messages'
import {GlobalContext} from "./globalContext";
// import {AssetsContext} from "../../context/assetsContext";

const LanguagesProvider = ({children}) => {
    const{language}=useContext(GlobalContext)

    return (
        <IntlProvider
            locale={language?.shortcut}
            textComponent={Fragment}
            messages={messages[language?.shortcut]}
        >
            {children}
        </IntlProvider>

    )
}

export default LanguagesProvider;
