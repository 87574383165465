import React from 'react';
import ReactDOM from 'react-dom/client';
import {hydrate,render } from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import {GlobalContextProvider} from "./context/globalContext";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <GlobalContextProvider>
//                 <App/>
//             </GlobalContextProvider>
//         </BrowserRouter>
//     </React.StrictMode>
// );

const Ra=()=>
    <React.StrictMode>
        <BrowserRouter>
            <GlobalContextProvider>
                <App/>
            </GlobalContextProvider>
        </BrowserRouter>
    </React.StrictMode>

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<Ra />, rootElement);
} else {
    render(<Ra />, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
