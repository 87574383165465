import React from 'react';
import {CgUnavailable} from 'react-icons/cg'

const Unavailable = (props) => {
    return (
        <div className={'vw-100 vh-100 align-items-center justify-content-center d-flex flex-column'}>
            <div className={'h1 text-danger'} style={{fontSize:'10rem'}}>
                <CgUnavailable/>
            </div>
            <p className={'text-center'}>
                We apologize for the inconvenience, but the website is temporarily unavailable.
                Our team is working diligently to resolve the issue and restore full functionality as quickly as
                possible
            </p>

        </div>
    )
};

export default Unavailable;
