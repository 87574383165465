import React, {useEffect} from 'react';
import {Htag} from "../exportComponent";
import {FormattedMessage, useIntl} from "react-intl";

const Emergency = (props) => {
    const {formatMessage} = useIntl()

    function r() {
        let f = formatMessage({id: 'emergencyDetails'})?.split('$')
        f[1] = <a key={'150'} href="tel:+35227517132">{formatMessage({id: 'clickToCall'})}</a>
        return f
    }

    return (
        <div className={'p-2 w-100'}>
            <div className={'d-flex flex-wrap align-items-centers justify-content-center'}>
                <div className={'col-12 col-md-7 '}>
                    <h2 className={'color-text-primary text-capitalize'}><FormattedMessage id={'inCaseEmergency'}/></h2>
                    <h5 className={'mt-5 color-text-gray'}>{r()}.</h5>
                </div>
                <div className={'col-12 col-md-5 d-flex'}>
                    <img src="/images/emergency-girl.jpg" className={'img-fluid object-fit-cover'} alt=""/>
                </div>

            </div>
        </div>
    )
};

export default Emergency;
