import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import {TbCircleCheckFilled} from 'react-icons/tb'
import {FormattedMessage} from "react-intl";

const EmailSuccessfullyPage = (props) => {
    const location = useLocation();
    const navi = useNavigate();

// get userId
    const userName = location?.state?.name || null;

    useEffect(() => {
        if (!userName) {
            navi('/')

        }
    }, [])

    return (
        <div className={'w-100 bg-white'}>
            <div className={'container py-2 gap-4 d-flex flex-column align-items-center justify-content-center'}>
                <h1 className={'display-1 text-success'}><TbCircleCheckFilled/></h1>
                <h4 className={'text-capitalize fw-bold color-text-primary'}>{userName}</h4>
                <div className={'text-center'}>
                    <h4>Your Message Has been successfully sent</h4>
                    <h5>We will reply in 5 days</h5>
                    <span>Fill free to call us any time check open time</span>
                </div>
                <div className={'col-12 text-center'}>
                <a href="/">
                    <span><FormattedMessage id={'backHomePage'}/></span>
                </a>
                </div>

            </div>

        </div>
    )
};

export default EmailSuccessfullyPage;
