import React from 'react';

const HTag = ({text,className}) => {
    return(
        <div className={`container justify-content-center mt-3 ${className}`}>
            <h1 className={'text-center h1 text-capitalize fw-bold position-relative z-1 backText'}>{text}
            </h1>
            <div className={'line-v d-block'}></div>
            {/*<span className={'backText shadow rounded-2'}></span>*/}
        </div>
    )
};

export default HTag;
