import React, {useEffect, useState} from 'react';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'

const ImageSlider = ({imagesData, slider}) => {
    const images = imagesData?.data || imagesData
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [stopInter, setStop] = useState(false);

    const nextImage = () => {
         setStop(true)
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

useEffect(()=>{
    let x = null
    if (slider != false && !stopInter) {
        x = setInterval(() => {
            nextImage()
        }, 6000)
    }

    return () => clearInterval(x)
},[stopInter])

    useEffect(() => {
        if (imagesData?.index) {
            setCurrentImageIndex(imagesData.index)
        }

    }, [])

    const previousImage = () => {
        setStop(true)
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };
    return (
        <div className="w-100 h-100 position-relative user-select-none">
            <div
                className={'action-buttons position-absolute w-100 h-100 p-2 align-items-center d-flex justify-content-between'}>
                <span onClick={previousImage}
                      className={'z-2 display-2 text-white pointer-event-cursor'}> <MdKeyboardArrowLeft/></span>
                <span onClick={nextImage} className={'z-2 display-2 text-white pointer-event-cursor'}
                      style={{right: 0}}><MdKeyboardArrowRight/></span>
            </div>
            {
                slider && <div className={'overflow-image-clinic'}></div>
            }


            <img key={images[currentImageIndex]} src={images[currentImageIndex]} alt="Slider Image"
                 className={`img-fluid ${slider && 'animate'} object-top ${!slider ? 'object-fit-contain' : 'object-fit-cover'}`}/>
        </div>
    );
};

export default ImageSlider;
