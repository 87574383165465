import React from 'react';

const Loading = (props) => {
    return(
        <div className={'position-fixed w-100 bg-opacity-50 vh-100 bg-dark d-flex justify-content-center align-items-center'} style={{zIndex:1500}}>
            <div className={'position-relative col-md-2 col-5 d-flex align-items-center justify-content-center'}>
                <span className={'position-absolute waves'}></span>
                <img className={'img-fluid object-fit-contain col-9 img'}  src={'/images/Asset 3.png'} alt={'dentapaul logo'}/>
            </div>

            {/*<div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">*/}
            {/*    <span className="visually-hidden">Loading...</span>*/}
            {/*</div>*/}

        </div>
    )
};

export default Loading;
