import React, {useRef} from 'react';
import {NavLink as Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {ImLocation} from "react-icons/im";
import {TfiEmail} from "react-icons/tfi";
import {BsFillTelephoneFill, BsFacebook, BsInstagram, BsLinkedin} from "react-icons/bs";
import {closeNavbar} from "../navbar/navbar";

const Footer = (props) => {
    const data = [
        {
            name: 'address',
            icon: <ImLocation/>,
            titleTag: 'Detnapaul address',
            details: '4 rue du commerce Dudelange 3450 Deuxième étage'
        },
        {
            name: 'email',
            icon: <TfiEmail/>,
            titleTag: 'Detnapaul email',
            details: 'info@dentapaul.lu'
        }, {
            name: 'mobile',
            icon: <BsFillTelephoneFill/>,
            titleTag: 'Detnapaul mobile',
            details: ['+352 2751-7132', '+352 621-357-247']
        },
    ]

    const t = useRef()

    function phoneCall() {
        t?.current?.click()
    }

    return (
        <div className={'footer bg-body-tertiary'}>
            <div
                className={'d-flex color-text-primary container-xxl flex-wrap align-items-stretch justify-content-center gap-2 gap-md-5'}>
                <div className={'text-light-blue col-12 color-text-primary col-md-3'}>
                    <h1><FormattedMessage id={'followus'}/></h1>
                    <div className={'d-flex w-100 gap-5'}>
                        <a className={'h1'}
                           href={'https://www.linkedin.com/in/rustom-paul-3094b6103/'}><BsLinkedin/></a>
                        <a className={'h1'} href={'https://www.instagram.com/dr_paul_rustom/'}><BsInstagram/></a>
                        {/*<h1><BsFacebook/></h1>*/}
                        {/*<h1><BsFacebook/></h1>*/}
                    </div>
                    {/*<span><FormattedMessage id={'whyChooseusDetails'}/></span>*/}
                </div>

                <div className={'d-flex col-12 text-light-blue col-md-3 flex-column'}>
                    <h1 className={'color-text-primary'}><FormattedMessage id={'links'}/></h1>
                    <ul className="list-unstyled  d-flex flex-column justify-content-start gap-2 gap-md-4 col-12">
                        <li className="nav-item" onClick={closeNavbar}>
                            +
                            <Link to={'/'}>
                                <FormattedMessage id={'home'}/>
                            </Link>
                            {/*<a className="nav-link active" aria-current="page" href="#">Home</a>*/}
                        </li>
                        <li className="nav-item" onClick={closeNavbar}>
                            +
                            <Link to={'/contact'}>
                                <FormattedMessage id={'contact'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Features</a>*/}
                        </li>
                        <li className="nav-item" onClick={closeNavbar}>
                            +
                            <Link to={'/openingHours'}>
                                <FormattedMessage id={'openingHours'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Pricing</a>*/}
                        </li>
                        <li className="nav-item" onClick={closeNavbar}>
                            +
                            <Link to={'/services'}>
                                <FormattedMessage id={'services'}/>
                            </Link>
                            {/*<a className="nav-link" href="#">Pricing</a>*/}
                        </li>
                    </ul>
                </div>
                <div className={'d-flex color-text-primary col-12 col-md-3 flex-column'}>
                    <a href="tel:+352621357247" className={'d-none'} ref={t}></a>
                    <h1><FormattedMessage id={'contactus'}/></h1>
                    {
                        data.map((e, index) =>
                            <div key={index} className={'col-12 d-flex gap-5 align-items-center'}>
                                <h1 className={'text-light-blue footer-icon'}>{e.icon}</h1>
                                <div>
                                    <h5 className={'fw-bold text-lowercase text-uppercase'}>{<FormattedMessage
                                        id={e.name}/>}</h5>

                                    <h6 translate={"no"} title={e.titleTag} onClick={phoneCall}>{
                                        typeof e.details == 'object' ? e.details.map(n =>
                                                <span key={n} className={'d-block'}>{n}</span>)
                                            : e.details

                                    }</h6>
                                </div>
                            </div>
                        )
                    }
                </div>


            </div>
            <span className={'mt-5 container color-text-gray text-center d-block'} style={{fontSize:'11px'}}>Developed and Published by<a
                href="https://www.rawad.net" className={'color-text-gray fw-bold'}>www.rawad.net</a></span>

        </div>
    )
};

export default Footer;
