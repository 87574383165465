import React, {useEffect, useRef, useState} from 'react';


const LazyLoading = ({src, width, className, height, file, alt,...props}) => {
    const placeholderRef = useRef(null)
    const [shown, setShown] = useState(false)
    useEffect(() => {
        const obsrv = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    setShown(true)
                    obsrv.unobserve(placeholderRef.current)
                }
            }
        )
        if (placeholderRef && placeholderRef.current) {
            obsrv.observe(placeholderRef.current)
        }
    }, [shown])

    return (
                    <img
                        ref={placeholderRef}
                        src={shown ? src : '/images/Asset 3.png'}
                        width={width || '100%'}
                        height={height || '100%'}
                        className={className}
                        alt={alt || ''}
                        {...props}
                    />
    )
};

export default LazyLoading;
