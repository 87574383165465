import './style.scss'
import m from '../../assets/images/Asset 2@0.75x.png'
import {LazyLoading} from "../exportComponent";


const serviceCard = (props) => {
    const data = props?.data
    const browser = 'Chrome'
    function NewlineText(props) {
        const text = props;
        const newText = text.split('.').map((str,index) => <p key={index}>{str}.</p>);

        return newText;
    }

    return (
        <div className={`w-100 ${data.img == 'emergency.jpg'?'emergency':''}`}>
            <div className={`d-flex m-2  flex-wrap gap-3 align-content-stretch justify-content-between ${props.index %2==0 ? 'reverse-card':''}`}>
                <div className={'col-12 col-md-6 d-flex position-relative'}>
                    <LazyLoading className={'z-3 img-fluid object-fit-cover'} src={`/images/${data.img}`}
                    onClick={()=>props.setImgs(`/images/${data.img}`)}

                    />
                    {/*<img className={'z-2 img-fluid object-fit-cover'} src={`/images/${data.img}`}/>*/}
                    <div className="overflow-image shadow" style={{right:props.index %2==0 ? '0':''}}></div>
                </div>
                <div className={'col-12 col-md-5'}>
                    <div className="projectCardContent my-2 my-md-5">
                        <h2 className={'main-text-color fw-bold my-4 text-capitalize'}>{data?.head}</h2>
                        <span className={'secondary-text-color mb-0'}>{NewlineText(data?.desc)}</span>
                        {/*<input type={'checkbox'} className={'expand-button'}*/}
                        {/*       onClick={async (e) => {*/}
                        {/*           if (browser.toLowerCase()!=='chrome'){*/}
                        {/*               let r = await document.getElementById(`${data.desc}`) //readmore button is not working on fox*/}
                        {/*               if (e.target?.checked) {*/}
                        {/*                   r.classList.add('active')*/}
                        {/*               } else {*/}
                        {/*                   r.classList.remove('active')*/}
                        {/*               }*/}
                        {/*           }*/}
                        {/*       }}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
            <div className={`line-v ${props.index==props.dataLength -1 ?'d-none':''}`}></div>
        </div>
    )
};

export default serviceCard;


/*
<div className="col-12 col-md-6 col-lg-4 border-danger p-3 shadow-sm bg-white">
    <div className="imagContainer m-auto">
        {/!*<LazyLoad src={data?.img}/>*!/}
        <img src={props.data.img && require(`../../assets/images/${props.data.img.toLowerCase()}`) || m} className={'img-fluid'}/>

    </div>
    <div className="projectCardContent my-5">
        <h2 className={'main-text-color fw-bold my-4'}>{data?.head}</h2>
        <span className={'secondary-text-color content'} id={data?.desc}>{data?.desc}</span>

        <input type={'checkbox'} className={'expand-button'}
               onClick={async (e) => {
                   if (browser.toLowerCase()!=='chrome'){
                       let r = await document.getElementById(`${data.desc}`) //readmore button is not working on fox
                       if (e.target?.checked) {
                           r.classList.add('active')
                       } else {
                           r.classList.remove('active')
                       }
                   }
               }}
        />
    </div>
    <div className="d-flex w-100 justify-content-center gap-4 mt-4">
        <Link to={data?.liveView} target="_blank">
            <button className={'btn btn-primary'}
                    onClick={() => _sendClick({type: `live_${data?.title}`})}
            >Live
            </button>
        </Link>
        <Link to={data?.code} target="_blank">
            <button className={'btn d-flex align-items-center'}
                    onClick={() => _sendClick({type: `code_${data?.title}`})}
            ><BsCodeSlash/>Code
            </button>
        </Link>
        {
            data.dashboard &&
            <Link to={data?.dashboard} target="_blank">
                <button className={'btn btn-primary'}
                        onClick={() => _sendClick({type: `dashboard_${data?.title}`})}
                >Dashboard
                </button>
            </Link>

        }
    </div>

</div>
*/

