import React from 'react';
import BreadCrumb from "../breadCrumb";
import {useLocation} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {Helmet} from "react-helmet";

const PagesHeader = (props) => {
    const {formatMessage, locale} = useIntl()
    const location = useLocation()
    const imgs=['slide-1.jpg','parodontologie.jpg','girl1.jpg','prophylaxis.jpg']

    return (
        <>
            <Helmet htmlAttributes={{lang: locale == 'fr' ? 'fr-lu' : locale}}>
                <title>DENTAPAUL | {formatMessage({id: location?.pathname.toLowerCase().replace('/', '')})}</title>
            </Helmet>
            <div className={'w-100 overflow-hidden position-relative'} style={{height: '200px'}}>
                <div className="overflow position-absolute w-100 h-100"></div>
                <div className={'position-absolute align-items-center justify-content-center  text-center'}
                     style={{left: '0', right: 0, bottom: 0, margin: "1rem auto"}}>
                    <h1 className={'color-text-primary d-inline-flex letter-spacing px-3 fw-bold text-capitalize display-4'}>
                        <FormattedMessage id={location?.pathname.replace('/', '')}/></h1>
                    <h4 className={'text-white container'}>
                        <BreadCrumb/>
                    </h4>
                </div>
                <img src={`/images/${imgs[Math.floor(Math.random()* imgs.length)]}`} alt="" className={'w-100 h-100 object-fit-cover'}/>
            </div>
        </>
    )
};

export default PagesHeader;
