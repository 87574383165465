import React from 'react';
import {Htag, LazyLoading} from "../components/exportComponent";
import {FormattedMessage} from "react-intl";
import {Helmet} from "react-helmet";


const Aboutus = (props) => {
    return(
        <div className={'position-relative w-100'}>
            <Htag text={<FormattedMessage id={'abutusTitle'}/>}/>
            {/*<h3 className={'fw-bold text-center'}>What we Offer to our Customers</h3>*/}
            <h6 className={'color-text-gray text-center mb-5'}><FormattedMessage id={'aboutusSubtitle'}/></h6>
            <div className="overflow-image-aboutus lang"></div>
            <div className={'d-flex container-xxl gap-md-3 gap-2 flex-wrap align-items-center justify-content-center'}>
                <div className={'col-12 col-lg-5 position-relative'}>
                    <LazyLoading className={'img-fluid'} src="/images/dr-img.png" alt="Dr.Rustom Paul"/>
                    {/*<img className={'img-fluid'} src="/images/dr-img.png" alt=""/>*/}
                </div>
                <div className={'col-12 col-lg-6 text-white'}>
                    {/*<h1 className={'display-1 fw-bold'}>About us</h1>*/}
                    <span className={'text-light'}><FormattedMessage id={'aboutusDesc'}/></span>
                </div>
            </div>

        </div>
    )
};

export default Aboutus;
