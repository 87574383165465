import React, {useContext} from 'react';
import {BsFillTelephoneFill} from "react-icons/bs";
import {TbLanguage} from "react-icons/tb";
import {MdEmail} from "react-icons/md";
import {GlobalContext} from "../../context/globalContext";
// import {} from 'react-intl'

const Languages = (props) => {
    const {language, _setLanguageToContext,setLoading} = useContext(GlobalContext)
function handleClick(e){
        setLoading(true)
        setTimeout(()=>{
            _setLanguageToContext(e.target.getAttribute('data'))
            setLoading(false)
        },1000)


}
    const Languages = () => {
        let la = [{
            name: 'english',
            shortcut: 'en',
            direction: 'ltr'

        }, {
            name: 'france',
            shortcut: 'fr',
            direction: 'ltr'

        }, {
            name: 'Deutsch',
            shortcut: 'de',
            direction: 'ltr'

        },
        ]
        return (
            la.filter(e => language?.shortcut != e?.shortcut)
        )
    }

    return (
        <div className={'w-100 bg-dark p-3 lang'}>
            <div className={'container-xxl justify-content-between align-items-center d-flex gap-4'}>

                <div className={'d-flex gap-1 align-items-center'}>
                    <h1 className={'icon-color'}><TbLanguage/></h1>
                    {
                        Languages()?.map((e,index) =>
                            <a hrefLang={e.shortcut} key={index} className={'btn btn-primary text-capitalize btn-background'} data={JSON.stringify(e)}
                                  onClick={(e) => handleClick(e)}>{e.name}</a>
                        )
                    }
                </div>
                <div className={'d-flex d-none d-md-flex gap-2 align-items-center'}>
                    <div>
                        <span className={'text-white m-1 icon-color'}><BsFillTelephoneFill/></span>

                        <span className={'text-white h6 secondary-font'}>+352-2751-7132</span>

                    </div>
                    <div>
                        <span className={'text-white m-1 icon-color'}><MdEmail/></span>
                        <span className={'text-white h6'}>info@dentapaul.lu</span>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default Languages;
