import React, {useContext} from 'react';
import data from "../assets/tsconfig.json";
import {Htag, ServiceCard} from "../components/exportComponent";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../context/globalContext";

const ServicesPage = (props) => {
    const {language,setImgs} = useContext(GlobalContext)
    return (
        <>
            <div className={'page'} id={'service'}>
                <div className={'container-xxl'}>
                <Htag text={<FormattedMessage id={'servicesTitle'}/>}/>
                {/*<h3 className={'fw-bold text-center'}>What we Offer to our Customers</h3>*/}
                <h6 className={'color-text-gray text-center mb-5'}><FormattedMessage id={'servicesSubtitle'}/></h6>
                {/*<div className={'d-flex justify-content-center'}>*/}
                {/*    <img src={line} alt="" className={'icon'}/>*/}
                {/*</div>*/}
                <div className={'d-flex flex-wrap gap-1 row-gap-1'}>
                    {
                        data[`services_${language?.shortcut}`]?.map((e, index) =>
                            <ServiceCard setImgs={setImgs} data={e} key={index} index={index} dataLength={data[`services_${language?.shortcut}`].length}/>
                        )
                    }
                </div>
                </div>
            </div>
        </>
    )
};

export default ServicesPage;
