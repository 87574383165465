import React from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom'
import {Languages, Navbar, PagesHeader} from "../components/exportComponent";

const PagesLayout = (props) => {
    return (
        <div>
            <Languages/>
            <Navbar/>
            <PagesHeader/>
            <Outlet/>
        </div>
    )
};

export default PagesLayout;
