import './App.css';
import {Route, Routes} from 'react-router-dom'
import MainLayout from "./mainLayout/mainLayout";
import HomePage from "./pages/homePage";
import PagesLayout from "./pagesLayout/pagesLayout";
import ContactPage from "./pages/contactPage";
import Clinic from "./pages/clinic";
import Footer from "./components/footer/footer";
import LanguagesProvider from "./context/Languages";
import {useContext, useState} from "react";
import {GlobalContext} from "./context/globalContext";
import {Navbar, Loading, FullWidthImg} from "./components/exportComponent";
import ServicesPage from "./pages/servicesPage";
import OpenTimePage from "./pages/openTimePage";
import Error from "./pages/error";
import EmailSuccessfullyPage from "./pages/emailSuccessfullyPage";
import Unavailable from "./pages/unavailable";

function App() {
    const {language, loading, imgs, setImgState, imgState, settings} = useContext(GlobalContext)


    if (settings != null) {
        if (settings?.closeWebsite == true) {
            return <Unavailable/>
        } else {
            return (
                <LanguagesProvider lang={language}>
                    {
                        imgState && <FullWidthImg imgs={imgs} setCloseImg={setImgState}/>
                    }

                    {loading && <Loading/>}
                    {/*<Navbar/>*/}
                    {/*<Hero/>*/}
                    {/*<OpenTime/>*/}
                    <Routes>
                        <Route exact path={'/'} element={<MainLayout/>}>
                            <Route index element={<HomePage/>}></Route>
                        </Route>
                        <Route exact path={'/contact'} element={<PagesLayout/>}>
                            <Route index element={<ContactPage/>}></Route>
                        </Route>
                        <Route exact path={'/services'} element={<PagesLayout/>}>
                            <Route index element={<ServicesPage/>}></Route>
                        </Route>
                        <Route exact path={'/clinic'} element={<PagesLayout/>}>
                            <Route index element={<Clinic/>}></Route>
                        </Route>
                        <Route exact path={'/openingHours'} element={<PagesLayout/>}>
                            <Route index element={<OpenTimePage/>}></Route>
                        </Route>
                        <Route exact path={'/message'} element={<PagesLayout/>}>
                            <Route index element={<EmailSuccessfullyPage/>}></Route>
                        </Route>
                        <Route path={'*'} element={
                            <div>
                                <Navbar/>
                                <Error/>
                            </div>
                        }/>

                    </Routes>
                    <Footer/>
                </LanguagesProvider>
            );
        }
    }
    return null


}

export default App;
