import React from 'react';
import {Outlet} from 'react-router-dom'
import {Languages, Navbar} from '../components/exportComponent'

const MainLayout = (props) => {
    return (
        <div>
            <Languages/>
                <Navbar/>
                <Outlet/>
        </div>
    )
};

export default MainLayout;
